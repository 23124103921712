
const digest = '8a864fba9ce520141fa20e16b279c6f91c33b3fa6b185e35387f242809853a14';
const css = `._block_jnpha_1 {
  background-color: var(--color-white);
  border-bottom: 1px solid var(--color--neutral-20);
  border-top: 1px solid var(--color--neutral-20);
  margin-top: 1em;
}

@media only screen and (min-width: 960px) {
  ._block_jnpha_1 {
    border: 1px solid var(--color--neutral-20);
    border-radius: 0.25em;
  }
}
._blockHeader_jnpha_14 {
  display: flex;
  padding: 1rem;
  padding-right: 6px;
  width: 100%;
}

._blockTitle_jnpha_21 {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1;
  color: var(--color-neutral-40);
}

._groupSingleRow_jnpha_28 {
  border-top: 1px solid var(--color--neutral-20);
  display: flex;
  flex: 1 0 auto;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media only screen and (min-width: 960px) {
  ._groupSingleRow_jnpha_28 {
    flex-wrap: nowrap;
    padding-left: 0.75rem;
  }
}
@media only screen and (min-width: 1279px) {
  .minibar ._groupSingleRow_jnpha_28 {
    border-top: 1px solid var(--color--neutral-20);
    display: flex;
    flex: 1 0 auto;
    justify-content: space-between;
    padding-left: 1rem;
  }
}
@media only screen and (min-width: 1472px) {
  ._groupSingleRow_jnpha_28 {
    border-top: 1px solid var(--color--neutral-20);
    display: flex;
    flex: 1 0 auto;
    justify-content: space-between;
    padding-left: 1rem;
  }
}
._overheadTitle_jnpha_59 {
  font-weight: 600;
  line-height: 1;
  margin-top: 1.25rem;
  width: 80%;
  height: 40px;
  padding-left: 1em;
}
._overheadBadge_jnpha_67 {
  font-size: 12px;
  font-weight: 600;
  margin-left: 9px;
}
@media only screen and (min-width: 960px) {
  ._overheadTitle_jnpha_59 {
    width: 14em;
    padding-left: 0;
    margin-right: -9px;
  }
  ._overheadBadge_jnpha_67 {
    margin-left: 18px;
    padding: 4px;
    border-radius: 4px;
  }
}
@media only screen and (min-width: 1279px) {
  .minibar ._overheadTitle_jnpha_59 {
    width: auto;
    padding-left: 0;
  }
}
@media only screen and (min-width: 1472px) {
  ._overheadTitle_jnpha_59 {
    width: auto;
    padding-left: 0;
  }
}

._activitySelectPlaceholder_jnpha_97 {
  display: none;
  margin-right: 0px;
  margin-left: auto;
  width: 14rem;
}
@media only screen and (min-width: 960px) {
  ._activitySelectPlaceholder_jnpha_97 {
    display: none;
  }
}
@media only screen and (min-width: 1279px) {
  .minibar ._activitySelectPlaceholder_jnpha_97 {
    display: flex;
  }
}
@media only screen and (min-width: 1472px) {
  ._activitySelectPlaceholder_jnpha_97 {
    display: flex;
  }
}

._rowDeleteColumn_jnpha_119 {
  width: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-legacy-gray-3);
  order: 2;
  margin-left: auto;
  margin-right: 6px;
}
@media only screen and (min-width: 960px) {
  ._rowDeleteColumn_jnpha_119 {
    margin-left: 0;
    margin-right: 0;
    order: 6;
  }
}
@media only screen and (min-width: 1279px) {
  .minibar ._rowDeleteColumn_jnpha_119 {
    border-left: 1px solid var(--color--neutral-20);
    margin-left: 0;
    margin-right: 0;
    order: 6;
  }
}
@media only screen and (min-width: 1472px) {
  ._rowDeleteColumn_jnpha_119 {
    border-left: 1px solid var(--color--neutral-20);
    margin-left: 0;
    margin-right: 0;
    order: 6;
  }
}
._rowDeleteButton_jnpha_152 {
  border: none;
  cursor: pointer;
  background-color: transparent;
}

._rowViewDeleteColumn_jnpha_158 {
  width: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-legacy-gray-3);
  order: 6;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"block":"_block_jnpha_1","blockHeader":"_blockHeader_jnpha_14","blockTitle":"_blockTitle_jnpha_21","groupSingleRow":"_groupSingleRow_jnpha_28","overheadTitle":"_overheadTitle_jnpha_59","overheadBadge":"_overheadBadge_jnpha_67","activitySelectPlaceholder":"_activitySelectPlaceholder_jnpha_97","rowDeleteColumn":"_rowDeleteColumn_jnpha_119","rowDeleteButton":"_rowDeleteButton_jnpha_152","rowViewDeleteColumn":"_rowViewDeleteColumn_jnpha_158"};
export { css, digest };
  