
const digest = '43dcb00ccf615cdc18931ac4cfec905526cec64741f3f71e3ffe83e2998265fa';
const css = `._timesheetTableActions_hd6fx_1 {
  display: flex;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"timesheetTableActions":"_timesheetTableActions_hd6fx_1"};
export { css, digest };
  