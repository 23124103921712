
const digest = '92ce40832a809e27dbc82c95a6807466b81c91eb55dd6dcb1ef4ef57ffc9d1dc';
const css = `.schedule .body-content {
  padding: 0;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {};
export { css, digest };
  