
const digest = 'c1a194f96f3b3603df33df2b242c528949f9f0901bc5f89e8ba02e982bf693b7';
const css = `._message_11prj_1 {
  align-content: center;
  display: flex;
  font-weight: 600;
}

._message_11prj_1 svg {
  margin-right: 0.5rem;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"message":"_message_11prj_1"};
export { css, digest };
  